import React from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Text, Flex, Grid, GridItem, Image, Box, useColorMode } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./figuya-logo-5.png";
import { NavLink } from "../elements";
import { faSun } from "@fortawesome/pro-solid-svg-icons/faSun";
import { faMoon } from "@fortawesome/pro-solid-svg-icons/faMoon";
import { useTranslation } from "react-i18next";
import { SearchButton } from "./SearchButton";
import { BottomPart } from "./bottom-part";

export const Header = () => {
  const { locale } = useParams();
  const link = (maxAmount, minAmount = 0) => `/${locale}/products?` + new URLSearchParams({
    order: "popularity", direction: "desc", gross_selling_price_max: maxAmount, stock_state: 'stocked'
  })
  const { colorMode, toggleColorMode } = useColorMode();
  const { t } = useTranslation();
  return (
    <>
      <Box
        bg="orange.600"
      >
        <Box p="2" borderBottomWidth="2px" borderColor="orange.50">

          <Box maxWidth="1400px" m="auto" textAlign="center" color="orange.50">
            💖 Valentinstags-Special! 💖
            Jetzt 10% sparen mit Gutscheincode: <b>valentin</b> (Nur für Lagerware, Reduzierte Artikel ausgeschlossen)
          </Box>
        </Box>
        <Grid
          px={[2, 4]}
          py={[4, 8]}
          maxWidth="1400px"
          m="auto"
          gap="4"
          templateAreas={[
            `"logo top-menu" "search search"`,
            `"logo top-menu" "search search"`,
            `"logo search top-menu"`,
          ]}
          gridTemplateRows={["auto auto", "auto auto", "auto"]}
          gridTemplateColumns={[
            "140px 1fr",
            "160px 1fr",
            "180px 1fr auto",
          ]}
        >
          <GridItem area="logo" as={Link} to={`/${locale}`}>
            <Image
              alt="Figuya Logo"
              src={logo}
            />
          </GridItem>
          <GridItem
            display="flex"
            flexDirection="column"
            justifyContent="center"
            zIndex="200"
            area="search"
          >
            <Box height="2px" position="relative" top="-8px" id="search" />
            <SearchButton />
          </GridItem>
          <GridItem
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap={[2, 4]}
            area="top-menu"
          >
            <NavLink
              aria-label="toggle color mode"
              variant="header"
              onClick={toggleColorMode}
            >
              <FontAwesomeIcon icon={colorMode === "light" ? faMoon : faSun} />
            </NavLink>
            <NavLink
              size="lg"
              to={`/${locale}/${locale === "de" ? "ueber-uns" : "about-us"}`}
              variant="header"
            >
              {t("about-us")}
            </NavLink>
          </GridItem>
        </Grid>
      </Box>
      <BottomPart />
    </>
  );
};
